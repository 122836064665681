.navbar {
  background-color: #ffffff;
  padding: 0.5rem 4rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease;
}

body.dark-mode .navbar {
  background-color: #1a1a1a;
}

.logo {
  width: 50px;
  height: 50px;
  transition: transform 0.1s linear; /* Smooth continuous rotation */
}

.menu-toggle-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

/* Hide menu button on desktop, show only on mobile */
.menu-button {
  background: none;
  border: none;
  font-size: 1.8rem;
  cursor: pointer;
  color: #000000;
  transition: color 0.3s ease;
  display: block; /* Hide by default */
}

body.dark-mode .menu-button {
  color: #ffffff;
}

/* Show menu button only on mobile */
@media (max-width: 768px) {
  .menu-button {
    display: block; /* Visible only on mobile */
  }
}

.theme-toggle {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #000000;
  transition: color 0.3s ease;
  display: block; /* Always visible on both desktop and mobile */
}

body.dark-mode .theme-toggle {
  color: #ffffff;
}

.nav-links {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  display: flex;
  gap: 1.5rem;
  position: absolute;
  left: 55%;
  transform: translateX(-45%);
}

.nav-links li a {
  color: #000000;
  text-decoration: none;
  font-weight: 600;
  font-size: 1rem;
  text-transform: uppercase;
  transition: color 0.3s ease;
}

body.dark-mode .nav-links li a {
  color: #ffffff;
}

.nav-links li a:hover {
  color: #555555;
}

body.dark-mode .nav-links li a:hover {
  color: #cccccc;
}

/* Mobile Adjustments */
@media (max-width: 768px) {
  .navbar {
    padding: 0.5rem 2rem; /* Reduce padding for mobile */
  }

  .menu-button {
    display: block; /* Already set above, no change needed */
  }

  .theme-toggle {
    position: static; /* Remove absolute positioning on mobile */
  }

  .nav-links {
    position: fixed; /* Full-screen menu */
    top: 60px; /* Below navbar */
    left: 0;
    width: 100%; /* Match navbar width */
    height: calc(100vh - 60px); /* Full height minus navbar */
    background: #ffffff;
    flex-direction: column;
    justify-content: center; /* Center vertically */
    align-items: center; /* Center horizontally */
    gap: 2rem; /* Increase gap for better spacing */
    padding: 0;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-100%); /* Start off-screen */
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, visibility 0s 0.3s;
    z-index: 999; /* Below navbar but above content */
  }

  .nav-links.open {
    opacity: 1;
    visibility: visible;
    transform: translateY(0); /* Slide down into view */
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, visibility 0s;
  }

  .nav-links li a {
    font-size: 1.2rem; /* Slightly larger text for mobile */
  }

  body.dark-mode .nav-links {
    background: #1a1a1a;
  }
}
