/* Resume Section Base Styles */
.resume-section {
  opacity: 0;
  text-align: left;
  align-items: flex-start;
}

.resume-section.visible {
  animation: fadeInLeft 1s ease-out forwards;
}

.resume-section:not(.visible) {
  animation: fadeOutLeft 1s ease-in forwards;
}

.resume-container {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.resume-content {
  flex: 1;
  margin-left: 180px;
  max-width: calc(100% - 180px);
}

.resume-nav {
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  padding: 1rem 0;
  width: 100px;
}

/* Navigation Buttons */
.resume-nav-button {
  background: none;
  border: none;
  font-size: 1rem;
  color: #555;
  cursor: pointer;
  padding: 0.5rem;
  transition: all 0.3s ease;
  text-align: left;
}

.resume-nav-button.active {
  color: #fff;
  background: #000;
}

body.dark-mode .resume-nav-button {
  color: #ccc;
}

body.dark-mode .resume-nav-button.active {
  color: #000;
  background: #fff;
}

/* Education Section */
.modern-edu-content {
  position: relative;
  padding-left: 2rem;
  border-left: 3px solid #000;
  margin-left: 1.5rem;
}

body.dark-mode .modern-edu-content {
  border-left-color: #fff;
}

.university-info {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  margin: 0.5rem 0;
}

.university-dot {
  font-size: 0.7rem;
  color: #666;
}

.edu-duration {
  background: rgba(0, 0, 0, 0.1);
  padding: 0.3rem 0.8rem;
  border-radius: 1rem;
  display: inline-block;
  margin-top: 0.5rem;
  font-size: 0.9rem;
}

/* Compact Experience Cards */
.timeline-container {
  position: relative;
  padding-left: 40px;
}

.experience-item {
  position: relative;
  padding: 1.2rem 0;
}

.timeline-marker {
  position: absolute;
  left: -40px;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timeline-line {
  width: 2px;
  background: #000;
  flex-grow: 1;
  margin: 3px 0;
}

body.dark-mode .timeline-line {
  background: #fff;
}

.timeline-dot {
  font-size: 1rem;
  color: #000;
  background: #fff;
  border-radius: 50%;
}

body.dark-mode .timeline-dot {
  color: #fff;
  background: #000;
}

.experience-content {
  background: rgba(255, 255, 255, 0.05);
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  margin-bottom: 1rem;
}

.experience-content:hover {
  transform: translateX(8px);
}

.experience-title {
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  margin-bottom: 0.4rem;
}

.experience-icon {
  margin-right: 0.5rem;
  font-size: 1rem;
}

.star-icon {
  width: 1rem;
  height: 1rem;
}

.experience-duration {
  font-size: 0.85rem;
  margin-left: auto;
}

/* Skills Section */
.skills-list {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.skill-item {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.skill-name {
  width: 180px;
  font-size: 0.95rem;
}

.skill-bar {
  flex: 1;
  height: 8px;
  background: rgba(230, 230, 230, 0.664);
}

.skill-progress {
  height: 100%;
  background: linear-gradient(90deg, #000000, #001414);
  box-shadow: 0 2px 4px rgba(0, 180, 216, 0.2);
}

body.dark-mode .skill-progress {
  background: linear-gradient(90deg, #ffffff, #f1f1ff);
}

/* Mobile Adjustments */
@media (max-width: 768px) {
  .resume-content {
    margin-left: 85px;
    max-width: calc(100% - 90px);
  }

  .resume-nav {
    gap: 15px;
    margin-left: -20px;
    margin-top: 1rem;
  }

  .modern-edu-content {
    padding-left: 1rem;
    margin-left: 1rem;
  }

  .experience-content {
    padding: 0.8rem;
  }

  .experience-title {
    font-size: 1rem;
  }

  .experience-duration {
    font-size: 0.8rem;
  }

  .skill-name {
    width: 120px;
    font-size: 0.9rem;
  }
}
