.home-section {
  height: 100vh;
  padding: 2rem 5rem 0 18rem;
  box-sizing: border-box;
  background-color: #ffffff;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 2rem;
  position: relative;
  animation: fadeIn 1.5s ease-in-out forwards;
}

body.dark-mode .home-section {
  background-color: #1a1a1a;
}

.home-text {
  color: #1a1a1a;
  grid-column: 1 / 2;
  animation: fadeInLeft 1s ease-out forwards;
  text-align: center; /* Center text for desktop */
}

body.dark-mode .home-text {
  color: #ffffff;
}

.home-greeting {
  font-size: 3rem;
  font-weight: 600;
  font-family: "Terminus", monospace;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.home-star {
  width: 2rem;
  height: 2rem;
  margin-top: -0.4rem;
}

.home-role {
  font-size: 1.25rem;
  font-weight: 500;
  font-family: "Terminus", monospace;
  margin-bottom: 1rem;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  min-height: 1.5em;
  position: relative;
  border-right: 2px solid #1a1a1a;
}

body.dark-mode .home-role {
  border-right-color: #ffffff;
  color: #ffffff;
}

.role-text {
  display: inline-block;
  animation: fadeRole 5s infinite;
}

.sharez-link {
  color: #1a1a1a;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.3s ease;
}

body.dark-mode .sharez-link {
  color: #ffffff;
}

.sharez-link:hover {
  color: #007bff;
}

@keyframes fadeRole {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.home-about {
  font-size: 1rem;
  font-family: "Terminus", monospace;
  margin-bottom: 2rem;
  color: #1a1a1a;
}

body.dark-mode .home-about {
  color: #ffffff;
}

.home-btn {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center button and mouse */
  gap: 1rem; /* Space between button and mouse */
}

.home-contact-btn {
  display: inline-block;
  padding: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  color: #ffffff;
  border: 1px solid #1a1a1a;
  font-weight: 500;
  font-size: 12px;
  background: #1a1a1a;
  transition: all 0.5s;
  font-family: "Terminus", monospace;
}

body.dark-mode .home-contact-btn {
  color: #1e1e1e;
  border: 1px solid #ffffff;
  background: #ffffff;
}

.mouse-scroll {
  width: 16px;
  height: 60px;
  cursor: pointer;
}

.mouse {
  height: 30px;
  width: 16px;
  border-radius: 14px;
  border: 2px solid #1a1a1a;
  display: block;
}

body.dark-mode .mouse {
  border-color: #ffffff;
}

.wheel {
  height: 2px;
  width: 2px;
  border: 2px solid #1a1a1a;
  border-radius: 8px;
  display: block;
  margin: 3px auto;
  position: relative;
  animation: mouse-wheel 0.6s linear infinite;
}

body.dark-mode .wheel {
  border-color: #ffffff;
}

@keyframes mouse-wheel {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(6px);
  }
}

.home-image {
  grid-column: 2 / 3;
  display: flex;
  justify-content: flex-end;
  position: relative;
  margin-right: 2rem;
  animation: fadeInRight 1s ease-out forwards;
}

.profile-picture {
  width: 220px;
  height: 220px;
  border-radius: 50%;
  object-fit: cover;
  border: 5px solid #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

body.dark-mode .profile-picture {
  border-color: #1a1a1a;
}

.profile-picture:hover {
  transform: scale(1.05);
}

.home-social-links {
  position: absolute;
  top: 35%;
  left: 0.5rem;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
  animation: fadeInUp 1s ease-out forwards;
}

.home-social-link {
  font-size: 1.2rem;
  color: #1a1a1a;
  transition: all 0.6s;
}

body.dark-mode .home-social-link {
  color: #ffffff;
}

.home-social-link:hover {
  transform: rotate3d(0, 0, 1, 90deg);
}

/* Animation keyframes */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-40px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(40px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Media queries unchanged */
@media (max-width: 768px) {
  .home-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 2rem;
    gap: 1.5rem;
    height: auto;
  }

  .home-text,
  .home-about,
  .home-role {
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .home-image {
    display: flex;
    justify-content: center;
    align-items: center;
    order: -1;
  }

  .profile-picture {
    width: 200px;
    height: 200px;
    margin: 0 auto;
  }

  .home-greeting,
  .home-name,
  .home-role,
  .home-about {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .home-greeting {
    font-size: 2rem;
  }

  .home-name {
    font-size: 2rem;
  }

  .home-role {
    font-size: 1rem;
  }

  .home-about {
    font-size: 0.9rem;
  }

  .home-contact-btn {
    margin: 0 auto;
    display: block;
    align-self: center;
  }

  .home-social-links {
    display: flex;
    position: static;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-top: 1rem;
    width: 100%;
  }

  .mouse-scroll {
    position: static;
    margin: 1rem auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 480px) {
  .home-section {
    padding: 1.5rem 1rem 0 1rem;
    gap: 1rem;
  }

  .profile-picture {
    width: 180px;
    height: 180px;
  }

  .home-greeting {
    font-size: 1.5rem;
  }

  .home-name {
    font-size: 1.5rem;
  }

  .home-role {
    font-size: 0.9rem;
  }

  .home-about {
    font-size: 0.8rem;
  }

  .home-contact-btn {
    padding: 0.6rem;
    font-size: 8px;
  }

  .home-social-links {
    gap: 10px;
    margin-top: 0.5rem;
  }

  .home-social-link {
    font-size: 2rem;
  }

  .mouse-scroll {
    margin: 0.5rem auto 0;
  }
}
