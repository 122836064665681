.snapchat-section {
  opacity: 0;
  text-align: left;
  align-items: flex-start;
  background-color: #fefc02; /* Yellow background */
}

body.dark-mode .snapchat-section {
  background-color: #fefc02; /* White in dark mode */
}

.snapchat-section.visible {
  animation: fadeInLeft 1s ease-out forwards;
}

.snapchat-section:not(.visible) {
  animation: fadeOutLeft 1s ease-in forwards;
}

.snapchat-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.snapchat-title {
  font-weight: bold;
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #000000; /* Black text for contrast on yellow/white */
}

.snapchat-nav {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap; /* Allow wrapping on small screens */
}

.snapchat-nav-button {
  background: none;
  border: none;
  font-size: 1.2rem;
  color: #000000; /* Black text */
  cursor: pointer;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.snapchat-nav-button.active {
  background-color: #000000; /* Black background when active */
  color: #ffffff; /* White text when active */
  border-radius: 4px;
}

.snapchat-nav-button:hover {
  background-color: #555555; /* Gray hover */
  color: #ffffff;
}

.snapchat-page-content {
  background-color: rgba(
    255,
    255,
    255,
    0.9
  ); /* Slightly transparent white for contrast */
  padding: 1.5rem;
  border-radius: 8px;
  min-height: 200px; /* Consistent content height */
}

body.dark-mode .snapchat-page-content {
  background-color: rgba(
    0,
    0,
    0,
    0.9
  ); /* Slightly transparent black in dark mode */
}

.snapchat-page h3 {
  font-weight: bold;
  font-size: 1.25rem;
  color: #000000;
  margin-bottom: 1rem;
}

body.dark-mode .snapchat-page h3 {
  color: #ffffff;
}

.snapchat-page p {
  font-size: 1rem;
  color: #555555;
}

body.dark-mode .snapchat-page p {
  color: #cccccc;
}

.snapchat-page ul {
  list-style-type: disc;
  padding-left: 1.5rem;
  font-size: 1rem;
  color: #555555;
}

body.dark-mode .snapchat-page ul {
  color: #cccccc;
}

.snapchat-page li {
  margin-bottom: 0.5rem;
}

.snapchat-page strong {
  color: #000000;
}

body.dark-mode .snapchat-page strong {
  color: #ffffff;
}

/* Mobile Adjustments */
@media (max-width: 768px) {
  .snapchat-title {
    font-size: 1.5rem;
  }

  .snapchat-nav {
    gap: 0.5rem;
  }

  .snapchat-nav-button {
    font-size: 1rem;
    padding: 0.4rem 0.8rem;
  }

  .snapchat-page-content {
    padding: 1rem;
  }

  .snapchat-page h3 {
    font-size: 1.1rem;
  }

  .snapchat-page p,
  .snapchat-page ul {
    font-size: 0.9rem;
  }
}

.snapchat-page li {
  margin-bottom: 0.5rem;
}

.snapchat-page strong {
  color: #000000;
}

body.dark-mode .snapchat-page strong {
  color: #ffffff;
}

.snapchat-dev-badge {
  width: 100px; /* Adjust size as needed */
  height: auto;
  display: block; /* Ensure it respects margin auto */
  margin: 1rem auto; /* Center horizontally */
}

.snapchat-official {
  display: flex;
  flex-direction: column; /* Stack icon & text */
  align-items: center; /* Center horizontally */
  text-align: center;
  font-family: "Avenir Next", sans-serif;
  gap: 1rem; /* Space between icon & text */
  font-weight: bold;
  color: #000000;
}

.snapchat-company-text {
  font-weight: lighter;
  padding-top: 10px; /* Adjust spacing */
  display: block; /* Ensures it respects block-level spacing */
}

body.dark-mode .snapchat-official {
  color: #ffffff;
}

.snapchat-icon {
  font-size: 3rem;
  color: #000000;
}

body.dark-mode .snapchat-icon {
  color: #ffffff;
}

/* Mobile Adjustments */
@media (max-width: 768px) {
  .snapchat-title {
    font-size: 1.5rem;
  }

  .snapchat-nav {
    gap: 0.5rem;
  }

  .snapchat-nav-button {
    font-size: 1rem;
    padding: 0.4rem 0.8rem;
  }

  .snapchat-page-content {
    padding: 1rem;
  }

  .snapchat-page h3 {
    font-size: 1.1rem;
  }

  .snapchat-page p,
  .snapchat-page ul {
    font-size: 0.9rem;
  }

  .snapchat-dev-badge {
    width: 80px; /* Smaller on mobile */
    margin: 1rem auto; /* Center horizontally */
  }

  .snapchat-official {
    display: flex;
    flex-direction: column; /* Stack icon & text */
    align-items: center; /* Center horizontally */
    justify-content: center; /* Center vertically */
    text-align: center;
    gap: 0.5rem; /* Space between icon & text */
    font-family: "Avenir Next", sans-serif;
    font-weight: 500;
    color: #000000;
  }

  body.dark-mode .snapchat-official {
    color: #ffffff;
  }

  .snapchat-icon {
    font-size: 2.5rem; /* Adjust icon size */
    color: #000000;
  }

  body.dark-mode .snapchat-icon {
    color: #ffffff;
  }

  .snapchat-text {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center text */
  }

  .snapchat-title-text {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .snapchat-company-text {
    font-size: 1rem;
    font-weight: lighter;
  }
  .snapchat-title-text {
    font-size: 1rem;
  }
}

/* 📱 Mobile Responsive */
@media (max-width: 768px) {
  .snapchat-icon {
    font-size: 2rem;
  }

  .snapchat-title-text {
    font-size: 1.2rem;
  }

  .snapchat-company-text {
    font-size: 1rem;
  }
}
/* Add to src/styles/snapchat.css */

/* Existing styles remain unchanged; add this at the bottom */

.view-all-lenses-button {
  display: inline-block;
  margin-top: 2vw;
  padding: clamp(8px, 1.5vw, 12px) clamp(15px, 3vw, 20px);
  background-color: #fefc02; /* Black background for contrast */
  color: #000000; /* White text */
  text-decoration: none;
  font-size: clamp(0.9rem, 2vw, 1.2rem);
  font-weight: bold;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.view-all-lenses-button:hover {
  background-color: #cfcfcf; /* Darker gray on hover */
}

.LensProjects {
  padding: 2vw;
  background-color: #ffffe6;
  text-align: center;
  width: 100%;
  max-width: 100vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.LensProjects h3 {
  font-weight: bold;
  font-size: clamp(1.5rem, 3vw, 2.5rem);
  color: #000000;
  margin-bottom: 2vw;
}

.LensProjects-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns */
  gap: 2vw;
  width: 80%; /* Set to 60% of viewport width */
  max-width: 800px; /* Cap for larger screens */
  margin: 0 auto;
}

.LensProjects-card {
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
}

.LensProjects-card:hover {
  transform: translateY(-5px);
}

.LensProjects-preview {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.LensProjects-preview video,
.LensProjects-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* or 'contain' based on your preference */
}

.LensProjects-content {
  padding: 1vw;
  text-align: center; /* Centered text */
}

.LensProjects-content h4 {
  font-size: clamp(1rem, 2vw, 1.2rem);
  color: #000000;
  margin: 0.5vw 0;
}

.LensProjects-content p {
  font-size: clamp(0.8rem, 1.5vw, 1rem);
  color: #333333;
  margin: 0.5vw 0;
}

.LensProjects-view-source-button {
  background-color: #000000;
  color: #ffffff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: clamp(0.8rem, 1.5vw, 1rem);
  margin-top: 0.5vw;
  transition: background-color 0.3s ease;
}

.LensProjects-view-source-button:hover {
  background-color: #333333;
}

.LensProjects-code-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.LensProjects-code-modal-content {
  background: #ffffff;
  padding: 2vw;
  border-radius: 10px;
  position: relative;
  width: 90%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
}

.LensProjects-code-block {
  background: #f4f4f4;
  padding: 1rem;
  border-radius: 5px;
  font-size: 10px;
  font-family: monospace;
  font-size: clamp(0.8rem, 1.5vw, 1rem);
  color: #333333;
  white-space: pre-wrap;
}

.LensProjects-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #ff0000;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 1rem;
  cursor: pointer;
}

/* Responsive Design */
@media (max-width: 768px) {
  .LensProjects-grid {
    width: 80%; /* Slightly wider on tablets */
    gap: 1vw;
  }

  .LensProjects-preview {
    height: 150px;
  }
}

@media (max-width: 480px) {
  .LensProjects {
    padding: 1vw;
  }

  .LensProjects-grid {
    grid-template-columns: 1fr; /* Stack on small screens */
    width: 90%;
  }

  .LensProjects-preview {
    height: 200px;
  }

  .LensProjects-content h4,
  .LensProjects-content p,
  .LensProjects-view-source-button {
    font-size: clamp(0.7rem, 1.2vw, 0.9rem);
  }
}
body.dark-mode .LensProjects {
  background-color: #191900;
}
body.dark-mode .LensProjects-code-modal-content {
  background: #151515;
  padding: 2vw;
  border-radius: 10px;
  position: relative;
  width: 80%;
  max-width: 660px;
  max-height: 80vh;
  overflow-y: auto;
}

body.dark-mode .LensProjects-code-block {
  background: #1e1e1e;
  color: #ffffff;
}
body.dark-mode .LensProjects-card {
  background-color: rgb(34, 34, 34);
}
/* Dark Mode */
@media (prefers-color-scheme: dark) {
  .LensProjects {
    background-color: #000000; /* Black background */
  }

  .LensProjects h3,
  .LensProjects-content h4 {
    color: #ffffff; /* White text */
  }

  .LensProjects-card {
    background: #000000; /* White card background */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .LensProjects-content p {
    color: #ffffff; /* Black text on white card */
  }

  .LensProjects-view-source-button {
    background-color: #ffffff; /* White button */
    color: #000000; /* Black text */
  }

  .LensProjects-view-source-button:hover {
    background-color: #cccccc; /* Light gray hover */
  }

  .LensProjects-code-modal-content {
    background: #ffffff; /* White modal */
  }

  .LensProjects-code-block {
    background: #f4f4f4;
    color: #000000; /* Black text */
  }
}

.SnapchatSnapMap {
  padding: 2vw;
  background-color: #ffffe6;
  text-align: center;
  width: 100%;
  max-width: 100vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SnapchatSnapMap h3 {
  font-weight: bold;
  font-size: clamp(1.5rem, 3vw, 2.5rem);
  color: #000000;
  margin-bottom: 2vw;
}

.SnapchatSnapMap-content {
  width: 60%; /* Matches LensProjects width */
  max-width: 800px;
  margin: 0 auto;
}

.SnapchatSnapMap-section {
  margin-bottom: 2vw;
}

.SnapchatSnapMap-section h4 {
  font-size: clamp(1rem, 2vw, 1.2rem);
  color: #000000;
  margin: 1vw 0 0.5vw;
  text-align: left;
}

.SnapchatSnapMap-section p {
  font-size: clamp(0.8rem, 1.5vw, 1rem);
  color: #333333;
  margin: 0 0 1vw;
  text-align: left;
}

.SnapchatSnapMap-embed-container {
  width: 70%; /* Embed width set to 70% */
  max-width: 416px;
  margin: 0 auto;
}

.snapchat-embed {
  background: #c4c4c4;
  border: 0;
  border-radius: 40px;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.5), 0 1px 10px 0 rgba(0, 0, 0, 0.15);
  margin: 1px auto;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 650px;
}

.snapchat-embed-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.snapchat-profile-icon {
  background-color: #f4f4f4;
  border-radius: 50%;
  flex-grow: 0;
  height: 40px;
  width: 40px;
  margin: 16px;
  cursor: pointer;
}

.snapchat-embed-spacer {
  flex: 1;
}

.snapchat-view-more {
  background-color: yellow;
  width: 100%;
  padding: 10px 20px;
  border: none;
  border-radius: 0 0 40px 40px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.SnapchatSnapMap-add-business-button {
  margin-top: 2vw;
}

.snapmap-contact-btn {
  background-color: #fffc00; /* Yellow */
  color: #000000;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: clamp(0.9rem, 1.5vw, 1.2rem);
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.snapmap-contact-btn:hover {
  background-color: #e6e300; /* Slightly darker yellow */
}

/* Responsive Design */
@media (max-width: 768px) {
  .SnapchatSnapMap-content {
    width: 80%; /* Slightly wider on tablets */
  }

  .SnapchatSnapMap-embed-container {
    width: 70%;
    max-width: 350px;
  }

  .snapchat-embed {
    height: 500px;
  }
}

@media (max-width: 480px) {
  .SnapchatSnapMap {
    padding: 1vw;
  }

  .SnapchatSnapMap-content {
    width: 90%; /* Full width on small screens */
  }

  .SnapchatSnapMap-embed-container {
    width: 100%;
    max-width: 100%;
  }

  .snapchat-embed {
    height: 450px;
    min-width: 0;
  }

  .SnapchatSnapMap-section h4,
  .SnapchatSnapMap-section p,
  .snapmap-contact-btn {
    font-size: clamp(0.7rem, 1.2vw, 0.9rem);
  }
}

body.dark-mode .SnapchatSnapMap {
  background-color: #191900;
}
body.dark-mode h3 {
  color: #ffffff;
}
body.dark-mode h4 {
  color: #ffffff;
}
body.dark-mode p {
  color: #c9c9c9;
}
/* Dark Mode */
@media (prefers-color-scheme: dark) {
  .SnapchatSnapMap {
    background-color: #000000; /* Black background */
    color: #ffffff;
  }
  .SnapchatSnapMap h3,
  .SnapchatSnapMap-section h4 {
    color: #ffffff; /* White text */
  }

  .SnapchatSnapMap-section p {
    color: #ffffff; /* White text */
  }

  .snapchat-embed {
    background: #333333; /* Darker gray for embed */
  }

  .snapchat-profile-icon {
    background-color: #444444; /* Darker gray for icon */
  }

  .snapmap-contact-btn {
    background-color: #fffc00; /* Yellow stays the same */
    color: #000000;
  }

  .snapmap-contact-btn:hover {
    background-color: #e6e300;
  }
}
